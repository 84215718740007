<template>
  <div class="auth-page">
    <div class="w-100">
      <!-- head -->
      <div class="text-center">
        <img
          class="cursor-pointer"
          @click="$router.push('/')"
          :src="require('@/assets/images/global/logo-blue.svg')"
          alt=""
        />
        <span class="font-24 icon-black--text d-block mt-4"
          >Sign in to Ofero Admin</span
        >
      </div>

      <!-- content -->
      <div class="auth-page__content radius-15 pt-8 pb-4 px-5 mt-7">
        <v-form
          v-model="valid"
          ref="signinForm"
          @submit.prevent="submitHandler"
        >
          <!-- email -->
          <div>
            <span class="d-block font-16 icon-black--text"
              >Email Address</span
            >
            <base-input
              v-model="user.email"
              class="mt-1"
              placeholder="example@gmail.com"
            ></base-input>
          </div>
          <!-- password -->
          <div class="mt-5 d-flex align-center justify-space-between mb-1">
            <span class="d-block font-16 icon-black--text">Password</span>
            <span class="blue--text">Forget Password?</span>
          </div>
          <base-input
            v-model="user.password"
            type="password"
            placeholder="*********"
          ></base-input>

          <!-- btn -->
          <v-btn
            class="mt-6 radius-15"
            height="45px"
            color="primary"
            elevation="0"
            block
            type="submit"
            ><span class="white--text font-16">Sign in</span></v-btn
          >
        </v-form>
        <!-- <div class="text-center mt-2">
          <span
            class="font-16 cursor-pointer blue--text d-block"
            v-if="!isAdmin"
            @click="isAdmin = true"
            >Login As Admin</span
          >
          <span
            class="font-16 cursor-pointer blue--text d-block"
            v-if="isAdmin"
            @click="isAdmin = false"
            >Login As User</span
          >
        </div> -->
      </div>

      <!-- bottom btn -->
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    isAdmin: false,
    user: {},
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.signinForm.validate();
      if (!valid) return;
      let urlApi = process.env.VUE_APP_SIGNIN_ADMIN;
      const res = await this.$axios.post(urlApi, {
        ...this.user,
      });
      if (res.data) {
        this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: "logged successfully",
          color: "success",
        });
        this.$router.go("/admin/packages");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 4% 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__content {
    background: $bg-grey;
  }
}
</style>
